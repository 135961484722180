<div class="header-holder">
  <spb-logo></spb-logo>

  <div class="borgo">
    <a href="https://www.borgohypotek.se/" target="_blank">
      <img alt="Borgo" src="./assets/borgo.svg">
    </a>
  </div>

  <div class="spb-filler"></div>

  <button [matMenuTriggerFor]="menu" mat-button>
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #menu>
    <button [routerLink]="['/', 'start' ]" mat-menu-item>Byt</button>
    <!-- button (click)="creator.create()" mat-menu-item>Starta ny ansökan</button-->
    <button (click)="userService.logout()" mat-menu-item>Logga ut</button>
  </mat-menu>

  <!--div [style.display]="'none'">
    <spb-create-application></spb-create-application>
  </div -->
</div>
