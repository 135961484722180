import {Injectable} from '@angular/core'
import {RequestService} from './request.service'
import {HttpClient} from '@angular/common/http'
import {
  BorgoLoginResult,
  CodeInput,
  CodeResponse,
  IBorgoApplicationListItem,
  IBorgoApplicationResult,
  IBorgoCollateral,
  IBorgoCollateralLoan,
  IBorgoInterestListPrice,
  IBorgoSupplementDocument,
  IBorgoSupplementDocumentRequest,
  IBorgoUserLoan,
  ICreditBreakDownPayload,
  IFEUpdateApplication,
  IKycPerson,
  IKycPersonDataset,
  IOfferingPayload,
  IPartyData,
  ISavedApplication,
  IUpdateApplicationResponse,
  IUserDataResult
} from 'sparbanken-syd-borgo'
import {catchError, forkJoin, map, NEVER, Observable, of, switchMap, tap} from 'rxjs'
import {environment} from '../../environments/environment'

import {Router} from '@angular/router'
import {MatDialog} from '@angular/material/dialog'
import {WaitComponent} from '../common/dialogs/wait/wait.component'
import {ApplicationService} from './application.service'

const USER_TOKEN_NAME = 'bo-user-loan-tokan'

export interface LoginResponse {
  url: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends RequestService {

  constructor(
    http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private applicationService: ApplicationService
  ) {
    super(http, USER_TOKEN_NAME)
  }

  /**
   * A getter to keep code cleaner
   */
  get sessionId(): string {
    return this.borgoAccessToken.sessionId
  }

  /**
   * Start the login procedure, it will trigger a long line
   * of 302s and eventually end up in /code
   */
  public login(): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/loan-login`
    return this.http.get<LoginResponse>(url)
  }

  /**
   * Start the login procedure, it will trigger a long line
   * of 302s and eventually end up in /code
   */
  public kycLogin(): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/kyc-login`
    return this.http.get<LoginResponse>(url)
  }

  public logout(): void {
    this.clearLogin()
    this.router.navigate(['låna', 'start']).then()
  }

  public clearLogin(): void {
    this.reset()
  }

  /**
   * Send the code and get something back, this is unique
   * for the user service. We make sure to store the result
   * but do not care for routing
   */
  public code(input: CodeInput): Observable<CodeResponse> {
    const url = `${environment.apiUrl}/code`
    return this.http.post<Required<CodeResponse>>(url, input).pipe(
      tap((res) => this.setToken(res))
    )
  }

  /**
   * Get what ever we have stored on the user. Always
   * use the access token so that we can verify that.
   * Since this call makes a call to borgo get user
   */
  public getUser(): Observable<IPartyData & IUserDataResult> {
    const path = `/user/${this.sessionId}`
    return this.getBorgo<IPartyData & IUserDataResult>(path).pipe(
      tap(r => {
        this.applicationService.currentUser$.set(r)
      })
    )
  }

  public getApplication(caseId: string, applicationId: string): Observable<IBorgoApplicationResult | null> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}`
    return this.getBorgo<IBorgoApplicationResult | null>(path).pipe(
      tap(a => {
        this.applicationService.currentApplicationResult$.next(a)
      }),
      switchMap(a => {
        return forkJoin([this.getSaveData(applicationId), of(a)])
      }),
      map(([, a]) => {
        return a
      })
    )
  }

  public getSaveData(applicationId: string): Observable<ISavedApplication | null> {
    const path = `/user/${this.sessionId}/admin/${applicationId}`
    return this.getBorgo<ISavedApplication | null>(path).pipe(
      tap(s => this.applicationService.currentSaveData$.next(s))
    )
  }

  public setSavedData(applicationId: string, data: Partial<ISavedApplication>): Observable<void> {
    const path = `/user/${this.sessionId}/admin/${applicationId}`
    return this.putBorgo<void, Partial<ISavedApplication>>(path, data)
  }

  public createApplication(): Observable<IBorgoApplicationResult> {
    const path = `/user/${this.sessionId}/applications`
    return this.putBorgo<IBorgoApplicationResult, any>(path, {})
  }

  public getApplications(): Observable<IBorgoApplicationListItem[]> {
    const path = `/user/${this.sessionId}/applications`
    return this.getBorgo<IBorgoApplicationListItem[]>(path)
  }

  public getLoans(): Observable<IBorgoUserLoan[]> {
    const path = `/user/${this.sessionId}/loans`
    return this.getBorgo<IBorgoUserLoan[]>(path).pipe(
      tap(r => this.applicationService.currentUserLoans$.set(r))
    )
  }

  public getCollaterals(): Observable<IBorgoCollateral[]> {
    return this.getBorgo<IBorgoCollateral[]>(`/user/${this.sessionId}/collaterals`)
  }

  public getCollateralLoans(collateralId: string): Observable<IBorgoCollateralLoan[]> {
    return this.getBorgo<IBorgoCollateralLoan[]>(`/user/${this.sessionId}/collaterals/${collateralId}/loans`)
  }

  public uploadDocument(caseId: string, applicationId: string, data: IBorgoSupplementDocumentRequest): Observable<IBorgoSupplementDocument> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}/documents`
    return this.putBorgo<IBorgoSupplementDocument, IBorgoSupplementDocumentRequest>(path, data)
  }

  public getDocument(caseId: string, documentId: string): Observable<any> {
    return this.getBorgo<IBorgoCollateralLoan[]>(`/user/${this.sessionId}/documents/${caseId}/${documentId}`)
  }

  public updateAmortization(caseId: string, applicationId: string): Observable<any> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}/amortization-requirement`
    return this.putBorgo<any, any>(path, {})
  }

  public setOfferResponse(caseId: string, applicationId: string, data: IOfferingPayload): Observable<any> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}/offering`
    return this.putBorgo<IOfferingPayload, any>(path, data)
  }

  public setCreditBreakdown(caseId: string, applicationId: string, data: ICreditBreakDownPayload): Observable<any> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}/breakdown`
    return this.putBorgo<any, ICreditBreakDownPayload>(path, data)
  }

  public onboard(caseId: string, applicationId: string): Observable<any> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}/onboard`
    return this.putBorgo<IOfferingPayload, any>(path, {})
  }

  public offer(caseId: string, applicationId: string, offer: any): Observable<any> {
    const path = `/user/${this.sessionId}/applications/${caseId}/${applicationId}/offer`
    return this.putBorgo<IOfferingPayload, any>(path, offer)
  }

  public getKyc(): Observable<IKycPerson> {
    const path = `/user/${this.sessionId}/kyc`
    return this.getBorgo<IKycPerson>(path)
  }

  public setKyc(kyc: Partial<IKycPersonDataset>): Observable<any> {
    const path = `/user/${this.sessionId}/kyc`
    return this.putBorgo<any, Partial<IKycPersonDataset>>(path, kyc)
  }

  public getInterests(): Observable<IBorgoInterestListPrice[]> {
    const path = `/user/${this.sessionId}/interests`
    return this.getBorgo<IBorgoInterestListPrice[]>(path)
  }

  /**
   * This should only be possible if we have a case selected.
   */
  public updateApplication(data: any): Observable<IUpdateApplicationResponse> {
    const path = `/user/${this.sessionId}/application`
    return this.putBorgo<IFEUpdateApplication, IUpdateApplicationResponse>(path, data)
  }

  public getLoan(accountId: string): Observable<any> {
    const path = `/user/${this.sessionId}/loans/${accountId}`
    return this.getBorgo<any>(path)
  }

  private setToken(res: Required<CodeResponse>): BorgoLoginResult {
    this.borgoAccessToken = {
      // Will add it later, including the refresh token
      token: res.accessToken,
      expires: res.expiry,
      refreshToken: res.refreshToken,
      // On refresh the session Id is not present
      sessionId: res.userId ?? this.sessionId
    }
    // This token is now permanently set in local storage.
    this.token$.next(this.borgoAccessToken)
    return this.borgoAccessToken
  }

  protected override refresh(): Observable<BorgoLoginResult> {
    return this.http.get<Required<CodeResponse>>(
      `${environment.apiUrl}/loan-login/${this.borgoAccessToken.refreshToken}`)
      .pipe(
        map((res) => this.setToken(res)),
        catchError(() => {
          this.reset()
          // If any open dialog close them.
          this.dialog.closeAll()
          this.dialog.open<WaitComponent, any, null>(WaitComponent, {
            data: {
              title: 'TimeOut',
              text: 'Din session har gått ut, du måste logga in igen.',
              closable: true
            }
          }).afterClosed().subscribe({
            next: () => {
              this.router.navigate(['user', 'start']).then()
            }
          })
          return NEVER
        })
      )
  }
}
