<div [style.flex-grow]="1"></div>
<div class="holder">
  @if (!minimal) {
    @if (!errored) {
      <div class="message">{{ message }}</div>
    } @else {
      <div class="message">{{ error }}</div>
    }
  }

  <mat-progress-bar
    [color]="errored ? 'warn' : 'primary'"
    [mode]="errored ? 'buffer': 'indeterminate'"
    [style]=""></mat-progress-bar>

  @if (!minimal && errored) {
    <button mat-stroked-button (click)="service.logout()">Avsluta</button>
  }
</div>