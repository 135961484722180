<spb-progress *ngIf="!error; else errorPanel" text="Verifierar"></spb-progress>
<ng-template #errorPanel>
  <div class="error-message">
    <h1>Något har gått fel</h1>
    <p>Vi kunde inte hämta några lånehandlingar att signera.</p>
    <p>Prova igen om en liten stund. Om felet kvarstår prova gärna på en annan enhet.</p>
    <p></p>
    <p><a routerLink="['start']">Prova igen</a></p>
    <p></p>
    <p>Kvarstår felet, kontakta din rådgivare eller vårt Kundcenter (telefon <a href="tel:+46411822000">0411-82 20
      00</a>
      alternativt e-post
      <a href="mailto:info@sparbankensyd.se">info&#64;sparbankensyd.se</a>).</p>
    <p>Detaljerad felinformation finns nedan, denna kan du behöva lämna vid kontakt med banken för vidare
      felsökning.</p>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        Detaljerad felinformation
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        {{ errorMessage }}
      </ng-template>
    </mat-expansion-panel>
  </div>
</ng-template>
