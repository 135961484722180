import {Component, inject} from '@angular/core'
import {UserService} from '../../services/user.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public userService = inject(UserService)
}
