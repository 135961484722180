import {inject} from '@angular/core'
import {filter, Observable} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

export class LocalStorageService<T> {

  private ils: Storage = inject(LOCAL_STORAGE)

  /**
   *
   * @param keyName - Just a name, try to keep it unique.
   * @param dats$ - The point of this is to keep this in sync with local storage.
   */
  constructor(
    private keyName: string,
    private dats$: Observable<T>
  ) {
    this.dats$
      .pipe(filter(Boolean))
      .subscribe({
        next: (val: T) => this.set(val)
      })
  }

  get(): T | null {
    try {
      return JSON.parse(this.ils.getItem(this.keyName) as string) as T
    } catch (_e) {
      return null
    }
  }

  set(val: T): void {
    this.ils.setItem(this.keyName, JSON.stringify(val))
  }

  clear(): void {
    this.ils.clear()
  }
}
