import {Component, inject, Input} from '@angular/core'
import {UserService} from '../../../services/user.service'
import {MatProgressBar} from '@angular/material/progress-bar'
import {MatButton} from '@angular/material/button'

@Component({
  selector: 'spb-progress-ng',
  templateUrl: './progress-ng.component.html',
  styleUrl: './progress-ng.component.scss',
  imports: [
    MatProgressBar,
    MatButton
  ],
  standalone: true
})
export class ProgressNgComponent {
  @Input() message: string = 'Vänta...'
  @Input() error: string = ''
  @Input() errored: boolean = false
  /**
   * Default true and then we only show the progress bar
   */
  @Input() minimal: boolean = true

  protected service = inject(UserService)
}
