import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'
import {MatButtonModule} from '@angular/material/button'
import {HeaderComponent} from './header/header.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {SafePipe} from './safe-pipe/safe.pipe'
import {ProgressComponent} from './progress/progress.component'
import {CodeComponent} from './code/code.component'
import {CommonModule} from '@angular/common'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatExpansionModule} from '@angular/material/expansion'
import {WaitComponent} from './dialogs/wait/wait.component'
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog'
import {StepperScrollDirective} from './stepper-scroll/stepper-scroll.directive'
import {ProgressNgComponent} from './components/progress/progress-ng.component'

@NgModule({
  declarations: [
    HeaderComponent,
    SafePipe,
    ProgressComponent,
    CodeComponent,
    WaitComponent,
    StepperScrollDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    ThemeModule,
    MatExpansionModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    ProgressNgComponent
  ],
  exports: [
    SafePipe,
    HeaderComponent,
    ProgressComponent,
    WaitComponent,
    StepperScrollDirective
  ]
})

export class SpbCommonModule {
}
