import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {DocumentService} from '../../services/document.service'
import {BorgoAuthSate, CodeInput, CodeResponse} from 'sparbanken-syd-borgo'
import {catchError, NEVER} from 'rxjs'
import {HttpErrorResponse, HttpParams} from '@angular/common/http'
import {DOCUMENTS_ROUTE_PATH, KYC_ROUTE_PATH, LETTER_ROUTE_PATH, LOAN_ROUTE_PATH} from '../../application/data-types'
import {UserService} from '../../services/user.service'


@Component({
  selector: 'spb-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {

  public error = false
  public errorMessage: string | undefined

  /**
   * This was introduced when I thought there was
   * a difference in paths. I leave it here in case
   */
  private paths: { [key in BorgoAuthSate]: string[] } = {
    AUTH: [DOCUMENTS_ROUTE_PATH, 'userId'],
    AUTOGIRO: [DOCUMENTS_ROUTE_PATH, 'userId'],
    SIGN: [DOCUMENTS_ROUTE_PATH, 'userId'],
    LETTER: [LETTER_ROUTE_PATH, 'klar'],
    LOAN: [LOAN_ROUTE_PATH, 'start'], // Go back to start so that we can load data.
    KYC: [KYC_ROUTE_PATH, 'input']
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DocumentService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {

    const params = new HttpParams({fromString: this.route.snapshot.fragment as string})
    const request: CodeInput = {
      code: params.get('code') as string,
      state: params.get('state') as string
    }

    if (!params.has('code')) {
      this.error = true
      this.errorMessage = params.get('error_description') as string
    } else {
      this.userService.code(request).pipe(
        catchError((err: HttpErrorResponse) => {
          this.error = true
          this.errorMessage = err.message
          return NEVER
        })
      ).subscribe({
        next: (res: CodeResponse) => {
          const path = this.paths[res.state]
            .map((p: string) => p.replace('userId', res.userId)
              .replace('null', ''))
          // If autogiro is registered, then we hide the autogiro.
          //
          if (res.autogiro) {
            this.dataService.addToken(res.autogiro)
          }
          this.router.navigate(path.filter((p: string) => p)).then()
        }
      })
    }
  }
}
