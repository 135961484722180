import {Inject, Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {CodeResponse, DocumentActionInput, DocumentItem, IAutogiroAccounts} from 'sparbanken-syd-borgo'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

const TEMP_KEY = 'TEMP_KEY'

export interface LoginResponse {
  url: string
}

export interface ISessionToken {
  /**
   * If we have an ID that means that we have a registered account,
   * else, we set it to the id of the request and false
   */
  id?: string
  temp?: boolean
  autogiroPossible: boolean
  accounts: IAutogiroAccounts[]
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCAL_STORAGE) private injectedStorage: Storage
  ) {
  }

  /**
   * Start the login procedure, it will trigger a long line
   * of 302s and eventually end up in /code
   */
  public login(): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/login-link`
    return this.httpClient.get<LoginResponse>(url)
  }

  /**
   * Same as login but starts another flow.
   */
  public startLetter(): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/letters`
    return this.httpClient.put<LoginResponse>(url, {})
  }

  /**
   * Get documents
   */
  public documents(id: string): Observable<DocumentItem[]> {
    const url = `${environment.apiUrl}/users/${id}/documents`
    return this.httpClient.get<DocumentItem[]>(url)
  }

  public approveDocuments(id: string, documents: DocumentItem[]): Observable<CodeResponse> {
    const data: DocumentActionInput = {
      action: 'APPROVE',
      documents: [],
      userId: id
    }
    // Make sure here not to send binary data etc
    data.documents = documents.map((d: DocumentItem) => ({
      id: d.id,
      hash: d.hash,
      title: d.title,
      category: d.category,
      isAutogiro: d.isAutogiro
    }))
    /**
     * Intention is to return 200 OK if ok, 404 if the document does not
     *  exist, etc.
     */
    const url = `${environment.apiUrl}/users/${id}/documents`
    return this.httpClient.put<CodeResponse>(url, data)
  }

  /**
   *
   */
  public startAutoGiroId(accountNumber: string, id: string): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/autogiro`
    return this.httpClient.post<LoginResponse>(url, {bban: `${accountNumber}`, id})
  }

  public startAutoGiroPnr(bban: string, personNummer: string): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/autogiro`
    return this.httpClient.put<LoginResponse>(url, {bban, personNummer})
  }

  /**
   * Add a random token
   * @param token -
   */
  public addToken(token: ISessionToken): void {
    this.injectedStorage.setItem(TEMP_KEY, JSON.stringify(token))
  }

  public getToken(): ISessionToken {
    const stored = this.injectedStorage.getItem(TEMP_KEY) ?? JSON.stringify(
      {
        autogiroPossible: false,
        accounts: []
      } as ISessionToken)
    return JSON.parse(stored)
  }

  public removeToken(): void {
    return this.injectedStorage.removeItem(TEMP_KEY)
  }

  public hideAutogiro(hide: boolean) {
    this.injectedStorage.setItem('HIDEOUS', `${hide}`)
  }

  public autoGiroHidden(): boolean {
    return this.injectedStorage.getItem('HIDEOUS') === 'true'
  }
}
