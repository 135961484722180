import {Injectable, signal} from '@angular/core'
import {BehaviorSubject, filter, Observable} from 'rxjs'
import {
  IBorgoApplication,
  IBorgoApplicationResult,
  IBorgoUserLoan,
  IPartyData,
  ISavedApplication,
  IUserDataResult
} from 'sparbanken-syd-borgo'
import {tap} from 'rxjs/operators'

const DEFAULT_USER: IPartyData & IUserDataResult = {
  address: {
    addressLine: [],
    location: '',
    postalCode: '',
    country: '',
    addressType: 'PRIMARY',
    addressValid: true
  },
  emails: [],
  name: '',
  personNummer: '',
  phones: [],
  partyId: ''
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private pCurrentApplication$ = new BehaviorSubject<IBorgoApplication | null>(null)
  public currentApplication$: Observable<IBorgoApplication | null>

  /**
   * Stores the current saved data, the data
   * we store in our backend. Normally null
   * in early stages.
   */
  public currentSaveData$ = new BehaviorSubject<ISavedApplication | null>(null)

  public currentApplicationResult$: BehaviorSubject<IBorgoApplicationResult | null> =
    new BehaviorSubject<IBorgoApplicationResult | null>(null)

  public readonly applicationId$ = signal<string>('')
  public readonly caseId$ = signal<string>('')

  public readonly currentUser$ = signal<IPartyData & IUserDataResult>(DEFAULT_USER)

  public readonly currentUserLoans$ = signal<IBorgoUserLoan[]>([])

  public readonly coApplicant$ = new BehaviorSubject<boolean>(false)

  constructor() {
    this.currentApplication$ = this.pCurrentApplication$.asObservable()

    this.currentApplicationResult$.pipe(
      tap(() => {
        this.applicationId$.set('')
        this.caseId$.set('')
      }),
      filter(Boolean)
    ).subscribe({
      next: a => {
        this.applicationId$.set(a.applicationId)
        this.caseId$.set(a.caseId)
        this.pCurrentApplication$.next(a.application)
      }
    })
  }
}
